<template>
  <v-list-item :title="title" :subtitle="subtitle" :prepend-icon="icon" :height="height">
    <template v-for="name in Object.keys($slots)" #[slotKey(name)]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
  </v-list-item>
</template>

<script setup lang="ts">
import type { VListItem } from "vuetify/components";

type SlotKeys = keyof Required<VListItem>["v-slots"]

const props = withDefaults(defineProps<{
  height?: string
  icon?: string
  title: string
  subtitle?: string
}>(), {
  height: "56px",
  icon: undefined,
  subtitle: undefined,
})
const { title, subtitle, icon, height } = toRefs(props)

const slotKey = (name: string) => name as SlotKeys
</script>
